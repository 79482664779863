<template>
<v-col style="padding-bottom:50px;">
  <v-row>

    <v-col cols="12" sm="9" md="8" lg="7" class="text-center mx-auto header-section">
      <v-icon class="d-none d-md-flex" style="font-size:5em; margin-bottom:-70px;" color="primary">mdi-briefcase-plus-outline</v-icon>
      <v-icon class="d-md-none" style="font-size:4em; margin-bottom:-20px;" color="primary">mdi-briefcase-plus-outline</v-icon>
      
      <template v-if="$route.params.companyId == null">
      <h2 class="mt-1 ml-4 primary--text">Référencer mon activité</h2>
      <br><br><br>
      <p class="font-papyrus">
        Vous souhaitez attirer plus de clients pirates, pour gagner plus de PRT ?<br>
        Référencez-vous pour gagner en visibilité, et obtenir un compte de dépôt dédié à votre activité !
        <br><br>
        <v-icon>mdi-check</v-icon> Géolocalisation sur la carte<br>
        <v-icon>mdi-check</v-icon> Compte de dépôt dédié
      </p>
      </template>
      <template v-else>
        <h2 class="mt-1 ml-4 primary--text">Gérer mon activité</h2>
        <br>
      </template>
    </v-col>

    <v-col v-if="categoryModel == null || companyModel == null"
            cols="11" sm="11" md="11" lg="9" 
            class="text-center mx-auto">
      <v-divider/>
      <div class="my-5 px-3 text-center">
        <!-- <b><v-icon>mdi-chevron-down</v-icon>  -->
        Sélectionnez un modèle parmis ceux proposés, ou cliquez sur "autre" si aucun modèle ne vous correspond.
      </div>
      <v-divider/>
 
        <template v-for="(category, i) in $store.state.app.data.companyCategory">
          <v-row :key="i">
            <v-col cols="12" class="text-left">
              <h3 :class="category.iconColor+'--text'" class="font-papyrus mt-5">
                <v-icon :color="category.iconColor">mdi-chevron-down</v-icon> {{ category.name }}
              </h3>
            </v-col>
            <template v-for="(company, cKey) in $store.state.company.companyModels">
              <v-col v-if="company.category.key == category.key" 
                     class="px-2" :key="cKey">
                <v-btn elevation="0" x-large outlined block :color="company.category.iconColor" 
                      class="company-card-scroll text-left" style="border: 1px dashed" 
                      @click="selectCompanyModel(category, company)">
                  <v-icon :title="company.name" class="mb-1 mr-2">
                    mdi-{{ company.iconFa }}
                  </v-icon>
                  <span class="font-papyrus">{{ company.name }}</span>
                </v-btn>
              </v-col>
            </template>
            <v-col>
            </v-col>
          </v-row>
        </template>

      <br><br><br><br>
      <br><br><br><br>
    </v-col>
    <v-col v-else  id="btn-cancel-model"
            cols="11" sm="9" md="8" lg="7" 
            class="text-center mx-auto mt-5 px-0">

      <v-btn @click="cancelModel">
        <v-icon small class="mr-1">mdi-arrow-left-circle</v-icon> Sélection du modèle
      </v-btn>

      <h2 class="font-papyrus my-3" :class="categoryModel.iconColor+'--text'">
        <v-icon :color="categoryModel.iconColor" class="mr-1">mdi-{{ categoryModel.icon }}</v-icon> 
        {{ categoryModel.name }}
      </h2>
      <span v-if="companyModel != null" class="font-papyrus" :class="companyModel.iconColor+'--text'">
        <v-icon :color="companyModel.iconColor"  class="mr-1 mb-2">mdi-{{ companyModel.iconFa }}</v-icon> {{ companyModel.name }}
      </span>
      <br><br>

      <v-divider></v-divider>

      <div class="mt-5 mb-2 pt-5 text-left">
        <b>
        <v-icon small class="mb-1">mdi-information</v-icon> 
        Information générales
        </b>
      </div>

      <v-text-field outlined label="Nom de votre activité..." v-model="name"
                    :error-messages="formError.name">
      </v-text-field>

      <v-textarea outlined label="Description de votre activité..." 
                  height="210" spellcheck="false"
                  v-model="description" :error-messages="formError.description">
      </v-textarea>

      <v-col cols="12" class="mt-5 text-left">
        <div class="mb-2 pb-4">
          <b><v-icon>mdi-map-marker</v-icon> Localisez votre activité</b>
        </div>
        <FormAddress :mustSaveAddress="false" :changeAddress="changeAddress"></FormAddress>
      </v-col>
      <br>
      <v-divider></v-divider>
      <br>

      <v-row>
      <v-toolbar elevation="0" color="transparent" class="mt-4">
        <v-spacer/>
        <v-btn class="mr-2" text :to="$route.params.companyId != null ? ('/company/'+$route.params.companyId) : '/map'">
          Annuler
        </v-btn>
        <v-btn color="green" :dark="formCompleted" elevation="0" :loading="saving"
              :disabled="!formCompleted" @click="saveCompany()">
          <v-icon small>mdi-content-save-check</v-icon> Enregistrer
        </v-btn>
      </v-toolbar>
      </v-row>

      <br><br><br><br>
    </v-col>

  </v-row>
</v-col>
</template>

<style>
  @import "~leaflet/dist/leaflet.css";
  
  .btn-keyboard{
    height:36px !important;
    font-size: 23px !important;
  }
  #map-container{
    z-index: 0;
  }
  
</style>

<script>

import axios from "axios"
import '@/assets/css/mainmap.css'

// import { i18n } from 'vue-lang-router';
//import core from '../../plugins/core.js'
import L from 'leaflet';
import FormAddress from '@/components/profil/FormAddress.vue'

const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'home',
  components: { FormAddress },
  data: () => ({
    openDialogCompany: false,
    attr: { name: 'gallery',
            label: '+ Ajouter des photos' },

    formError: {
      'category': '',
      'title': '',
      'text': '',
      'price': '',
      'address': '',
    },

    oldAmount: "",

    zoom:8,
    mapCenter: L.latLng(47.413220, -1.219482),
    url : config.mapTilesUrl,
    attribution:'', //&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    popupContent: "ok",

    categoryModel: null,
    companyModel: null,

    entityId: null,
    category: null,
    name: "",
    description: "",
    city: "",
    lat: 0, 
    lng: 0,

    companyCreatedId: null,
    saving: false
  }),
  mounted: async function(){

      console.log("this.$route.params.companyId", this.$route.params.companyId)
      this.$store.dispatch('app/incStat', '/create-company')
      this.$store.dispatch('app/fetchEntities', { entityType: "companyCategory", sort: { "order": 1 } })

      if(this.$store.state.auth.isLogin && (!this.$store.state.auth.user.isActive)) 
          this.$router.push("/market")

      this.$store.dispatch("company/fetchCompanyModels")

      this.init()

      // this.$root.$off('onImgLoaded').$on('onImgLoaded', (params) => { 
      //   console.log("onImgLoaded", params)
      //   this.$store.dispatch('auth/refreshUserData')
      //   this.$router.push("/company/" + this.companyCreatedId)
      // })

  },
  methods: {
    selectCompanyModel(category, company){
      this.categoryModel = category
      this.companyModel = company
      setTimeout(()=>{
        this.$vuetify.goTo('#btn-cancel-model')
      }, 100)
    },
    cancelModel(){
      this.categoryModel = null
      this.companyModel = null

    },
    init(){
      if(this.$route.params.companyId != null){
        const companies = this.$store.state.auth.user.companies.filter(company => (company._id == this.$route.params.companyId));
        if(companies.length > 0){
          let company = companies[0]
          this.name = company.name
          this.description = company.description
          this.companyModel = company.ownModel
          this.categoryModel = company.category
          this.city = company.city
          this.lat = company.coordinates[0]
          this.lng = company.coordinates[1]
        }
      }
      else{
          this.name = ""
          this.description = ""
          this.companyModel = null
          this.categoryModel = null
          this.city = ""
          this.lat = this.$store.state.auth.user.coordinates[1] 
          this.lng = this.$store.state.auth.user.coordinates[0]
      }
    },
    changeAddress(params){
        console.log("onChangeAddress", params)
        this.city = params.city
        this.lat = params.lat
        this.lng = params.lng
    },
    async saveCompany(){
      let params = {
        name: this.name,
        category: this.categoryModel._id,
        ownModel: this.companyModel._id,
        description: this.description,
        city: this.city,
        lat: this.lat,
        lng: this.lng,
      }
      console.log("saveCompany", params, this.companyModel._id)

      if(this.name == '')       this.formError.name        = 'Titre obligatoire'
      //if(this.description == '')this.formError.description  = 'Nom de l\'activité obligatoire'
      if(this.category == null) this.formError.category     = 'Merci de sélectionner une catégorie'
      if(this.lat == 0)         this.formError.address      = 'Merci de localiser l\'activité'

      if(this.$route.params.companyId != null)
        params.id = this.$route.params.companyId

      if(this.saving) return 

      this.saving = true
      const { data } = await axios.post('map/create-company', params)
      if(!data.error){
        console.log("ok create company", data)
        this.$store.dispatch('auth/refreshUserData')
        this.$router.push("/company/" + data.company._id)
      }else{
        console.log("error creating company", data)
      }
      this.saving = false
    },

    addNumToAmount(num){
      if(num == "." && this.amountMony.indexOf(num) >= 0) return
      //si le montant tapé fait déjà 4 caractère 
      if(this.amountMony.length >= 4 &&  this.amountMony.indexOf(".") == -1) return
      //si le nombre de chiffre apres la virgule est déjà de 4
      if(this.amountMony.length >= 4 
      && this.amountMony.indexOf(".") > 0
      && this.amountMony.length >= parseFloat(this.amountMony).toFixed(4).length)  return
      
      this.amountMony = this.amountMony + "" + num
    },
    removeNumToAmount(){
      this.amountMony = this.amountMony.substr(0, this.amountMony.length-1)
    },
    keydownAmount(event){
      if(event.key.length > 1 || this.amountMony == null) return

      //transforme le "0" en chaine vide, sauf si on rajoute un point
      if(this.amountMony == "0" && event.key != ".") this.amountMony = ""
      
      //si le montant tapé fait déjà 4 caractère 
      if(this.amountMony.length >= 4 &&  this.amountMony.indexOf(".") == -1) 
        event.preventDefault()

      //si le nombre de chiffre apres la virgule est déjà de 4
      if(this.amountMony.length >= 4 
      && this.amountMony.indexOf(".") > 0
      && this.amountMony.length >= parseFloat(this.amountMony).toFixed(4).length) 
        event.preventDefault()

      let ascii = event.key.charCodeAt(0)
      if((ascii < 48 || ascii > 57) //si ce n'est pas un nombre
      && (event.key != "." || this.amountMony.indexOf(".") > -1) //et que ce n'est pas un point, ni un 2eme point
      ) {
          event.preventDefault() //annule la frappe au clavier
      }
    },
    monyToEuro(){ 
      if(this.amountMony == "") return
        return "~" + (parseFloat(this.amountMony) * config.monyToEuro).toFixed(2) + "€" 
    },
  },
  
  computed: {
    formCompleted: function(){
      let completed = true 
      if(this.categoryModel == null) completed = false 
      if(this.name == "")            completed = false 
      if(this.lat == 0)              completed = false 

      return completed
    }
  },
  watch: {
      '$route.params.companyId': { immediate: true, 
          async handler () {
              this.init()
          }
      },
  }, 
}
</script>
